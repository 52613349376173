import React from "react";
import { Card, styled, Button, List, ListItem, ListItemText } from "@mui/material";
import Grid from '@mui/material/Grid2';
import CustomSlider from "../../components/Slider";
import OurCapabilities from "../../components/OurCapabilities";

const HomeWrapper = styled("div")(() => ({
  // "&": {
  //   marginTop: "40px",
  //   backgroundColor: "#000",
  //   color: "#fff"
  // },
  "& .section1": {
    textAlign: "center",
    marginTop: "125px",
    // minHeight: "85vh",
    // position: "relative",
    // transform: "translate(0, 35%)",
  },
  "& .innerWrapper": {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  "& .innerWrapper h1": {
      fontSize: "60px"
  },
   "& .section2": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px'
   },
  "& .section2 .MuiCard-root": {
    margin: "20px",
    height: "240px",
    padding: '20px'
  },
  "& .section2 .MuiCard-root.odd": {
    backgroundColor: "ivory",
  },
  "& .section2 .MuiCard-root.even": {
    backgroundColor: "#542999", //plum
    color: "#fff"
  },
  "& .section2 .MuiCard-root.even h3": {
    borderBottom: "1px solid #fff",
  },
  "& .section3": {
    textAlign: "center",
    height: "60vh",
    padding: "0 40px",
    // width: "740px",
    // margin: "10px auto",
  },
  "& .section3 .heading": {
    fontSize: "82px",
    fontWeight: "bold"
  },
  "& .section3 p": {
    fontSize: "28px",
    marginTop: "5px"
  },
  ".section4": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px'
    // height: '55vh',
    // lineHeight: 10
  },
  ".section5": {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px',
  },
  ".section5 h2": {
    display: 'block',
  }
}));

const Home = () => {
  return (
    <div className="container">
      <HomeWrapper>
        <div className="section1 mb-5">
          <CustomSlider />
        </div>
        <div className="section2 mt-5 mb-5">
          <Grid container spacing={1}>
            <Grid size={3}>
              <Card variant="outlined" className="odd">
                <h3>Achievements</h3>
                <h4>Successfully delivered 500+ projects with excellence.</h4>
              </Card>
            </Grid>
            <Grid size={3}>
            <Card variant="outlined" className="even">
              <h3>Vision</h3>
              <h4>Empowering Tomorrow, Today: Innovate with AI & ML</h4>
            </Card>
            </Grid>
            <Grid size={3}>
            <Card variant="outlined" className="odd">
              <h3>Our Journey</h3>
              <h4>Rebuilding with passion, delivering with precision.</h4>
            </Card>
            </Grid>
            <Grid size={3}>
            <Card variant="outlined" className="even">
              <h3>Client-Centric Approach</h3>
              <h4>Your vision, our mission – let's grow together.</h4>
            </Card>
            </Grid>
          </Grid>
        </div>
        <div className="section4 mt-5 mb-5">
          <br/>
          <OurCapabilities />
        </div>
        <div className="section5">
          <h2 className="mb-4">Why Partner With Us?</h2>
          <ul className="list-unstyled fs-5">
            <li>✔ Global Expertise, Local Insights – We combine international innovation with deep industry knowledge.</li>
            <li>✔ People-First Approach – Technology is a tool; empowering people is our priority.</li>
            <li>✔ Sustainable & Responsible Innovation – Creating value that benefits businesses, people, and the planet.</li>
          </ul>
        </div>
        {/* <div className="section6 mt-5">
          <h2 className="fw-bold">Let’s Shape the Future Together</h2>
          <p className="lead text-muted">Unlock new possibilities, drive innovation, and redefine what’s next.</p>
          <a href="#contact" className="btn btn-primary btn-lg px-4 py-2">Connect With Us Today</a>
        </div> */}
      </HomeWrapper>
    </div>
  )
}

export default Home;