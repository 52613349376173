import React from "react";

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <p>© 2025 Kaalpanik Technologies LLP. All rights reserved.</p>
    </footer>
  );
};

const footerStyle = {
  backgroundColor: "#000",
  color: "#fff",
  textAlign: "center",
  padding: "0.5rem",
  marginTop: "8px",
};

export default Footer;