import { Link } from "react-router-dom";

export const capabilitiesMenu = [
  <Link to='/cloud'>Cloud</Link>,
  <Link to=''>Cybersecurity</Link>,
  <Link to=''>Data and Artificial Intelligence</Link>,
  <Link to=''>Digital Engineering and Manufacturing</Link>,
  <Link to=''>Emerging Technology</Link>,
  <Link to=''>Ecosystem Partners</Link>,
  <Link to=''>Finance and Risk Management</Link>,
  <Link to=''>Infrastructure and Capital Projects</Link>,
  <Link to=''>Learning</Link>,
  <Link to=''>Marketing and Experience</Link>,
  <Link to=''>Metaverse</Link>,
  <Link to=''>Sales and Commerce</Link>,
  <Link to=''>Strategic Managed Services</Link>,
  <Link to=''>Strategy</Link>,
  <Link to=''>Supply Chain</Link>,
  <Link to=''>Sustainability</Link>,
  <Link to=''>Talent and Organization</Link>,
  <Link to=''>Technology Transformation</Link>,
];

export const industriesMenu = [
<Link to=''>Aerospace and Defense</Link>,
<Link to=''>Automotive</Link>,
<Link to=''>Banking</Link>,
<Link to=''>Capital Markets</Link>,
<Link to=''>Chemicals</Link>,
<Link to=''>Communications and Media</Link>,
<Link to=''>Consumer Goods and Services</Link>,
<Link to=''>Energy</Link>,
<Link to=''>Health</Link>,
<Link to=''>High Tech</Link>,
<Link to=''>Industrial</Link>,
<Link to=''>Insurance</Link>,
<Link to=''>Life Sciences</Link>,
<Link to=''>Natural Resources</Link>,
<Link to=''>Public Service</Link>,
<Link to=''>Private Equity</Link>,
<Link to=''>Retail</Link>,
<Link to=''>Software and Platforms</Link>,
<Link to=''>Travel</Link>,
<Link to=''>Utilities</Link>,
];

export const ourOrganizationMenu = [
<Link to=''>Leaders</Link>,
<Link to=''>Locations</Link>,
<Link to=''>360° Value Report</Link>,
];

export const howWeServeMenu = [
<Link to=''>Strategy and Consulting</Link>,
<Link to=''>Technology</Link>,
<Link to=''>Operations</Link>,
];

export const lifeAtKaalpanik = [
<Link to=''>Working here</Link>,
<Link to=''>Benefits</Link>,
<Link to=''>Our communities</Link>,
<Link to=''>Work environment</Link>
];